import localforage from 'localforage';
import { v4 as uuidv4 } from 'uuid';
import moment from 'moment';

import { promisifyAPI, makeParameterURI } from './api';
import { makeUserCredentials } from './user';
import { timeline as TIMELINE } from './constants';

const makeWelcomeTimelineItem = () => {
  const added = new Date();
  return {
    id: uuidv4(),
    type: TIMELINE.types.info,
    indicator: TIMELINE.indicators.info,
    label: 'VAccess',
    details: 'You have created your VAccess account. Welcome!',
    date: moment(added).format('D MMM YYYY'),
    open: true,
    bottom: true,
    added
  };
}

// Sorts by date, descending
function sortByDate(a, b) {
  // If an item is marked as being at the `bottom`, always sorts those last
  if (a.bottom) {
    if (!b.bottom) {
      return 1;
    }
  } else if (b.bottom) {
    return -1;
  }

  // If the event dates are equal, try to sort by the added date
  if (a.date === b.date) {
    return new Date(b.added) - new Date(a.added);
  }

  return new Date(b.date) - new Date(a.date);
}

function getItems(setter, opts = {}) {
  const { type, limit, modifier } = opts;
  return new Promise((resolve, reject) => {
    localforage.getItem(TIMELINE.keys.items)
    .then(value => {
      let items = (value || []).sort(sortByDate);

      if (type) {
        items = items.filter(x => x.type === type);
      }

      if (limit) {
        items = items.slice(0, limit);
      }

      if (modifier) {
        items = items.map(modifier);
      }

      if (setter) {
        setter(items);
      }

      resolve(items);
    })
    .catch(reject);
  });
}

function deleteTimelineItem(id) {
  const uri = makeParameterURI('timeline', { item: id });

  return makeUserCredentials()
  .then(credentials => promisifyAPI(uri, null, 'DELETE', credentials));
}

function backupUnsyncedTimelineItems() {
  return new Promise((resolve, reject) => {
    localforage.getItem(TIMELINE.keys.items)
    .then(value => {
      const items = (value || []).filter(item => !item.sync);
      items.map(backupTimelineItem);
    })
    .then(resolve)
    .catch(reject);
  });
}

function backupTimelineItems(items) {
  return Promise.all(items.map(backupTimelineItem));
}

function backupTimelineItem(item) {
  return new Promise((resolve, reject) => {
    makeUserCredentials()
    .then(credentials => {
      promisifyAPI('timeline', { item }, 'POST', credentials)
      .then(() => markTimelineItemAsSynced(item))
      .then(resolve)
      .catch(reject);
    })
    .catch(reject);
  });
}

function markTimelineItemAsSynced(item) {
  return new Promise((resolve, reject) => {
    localforage.getItem(TIMELINE.keys.items)
    .then(value => {
      const delta =  (value || []).map(x => x.id === item.id ? ({...x, sync: new Date()}) : x);
      return localforage.setItem(TIMELINE.keys.items, delta);
    })
    .then(resolve)
    .catch(reject);
  });
}

function restoreTimelineItems(email) {
  return makeUserCredentials()
  .then(credentials => promisifyAPI('timeline', null, 'GET', credentials));
}

export {
  sortByDate,
  getItems,
  makeWelcomeTimelineItem,
  backupUnsyncedTimelineItems,
  backupTimelineItems,
  backupTimelineItem,
  deleteTimelineItem,
  restoreTimelineItems
};
