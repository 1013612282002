const signInTimeout = 5; // minutes
const notificationInterval = 15; // seconds

const api = {
  uri: process.env.API_URI || 'https://vaccess-backend-staging.verj.cloud/api/VAccess',
  base: 'https://vaccess-backend-staging.verj.cloud'
};

const app = {
  uri: process.env.APP_URI || 'https://vaccess.app',
  path: process.env.APP_PATH || '',
  startPage: 'index.html',
  version: '2.0.9'
};

const website = {
  uri: process.env.HOST_URI || 'https://vaccess-website.verj.cloud'
};

const timeline = {
  types: {
    info: 'info',
    vaccination: 'vaccination',
    test: 'test',
    testGiven: 'testGiven',
    system: 'system'
  },
  keys: {
    items: 'timelineItems'
  },
  indicators: {
    success: 'success',
    warning: 'warning',
    danger: 'danger',
    info: 'info',
    none: 'none'
  },
  origins: {
    self: 'self'
  },
  labels: {
    pcrTest: 'PCR test given',
    pcrResults: 'PCR lab test result'
  }
};

const personal = {
  keys: {
    id: 'id',
    firstName: 'firstName',
    surname: 'surname',
    dob: 'dob',
    addressLine1: 'addressLine1',
    addressLine2: 'addressLine2',
    addressLine3: 'addressLine3',
    postcode: 'postcode',
    mobileNumber: 'mobileNumber',
    mobileVerified: 'mobileVerified',
    email: 'email',
    emailVerified: 'emailVerified',
    passcode: 'passcode',
    passcodeChangeRequired: 'passcodeChangeRequired',
    facemaskExemption: 'facemaskExemption',
    nhsNumber: 'nhsNumber',
    profileImage: 'profileImage',
    gender: 'gender',
    timestamp: 'signedInTimestamp',
    lateralFlowTestImage: 'lateralFlowTestImage',
    jwt: 'jwt',
    apiKey: 'apiKey',
    organisations: 'organisations',
    notifications: 'notifications',
    registeredShopInterest: 'registeredShopInterest',
  }
};

const onboarding = {
  steps: {
    welcome: 'welcome',
    user: 'user',
    complete: 'complete',
  },
  keys: {
    step: 'onboardingStep',
    complete: 'onboardingComplete',
    timestamp: 'onboardingCompletedTimestamp'
  },
  verifications: {
    smsVerificationPinLength: 5,
    recoveryCodeLength: 5,
  }
};

const installation = {
  keys: {
    complete: 'installed',
    skipped: 'installationSkipped',
    version: 'version'
  }
};

const facemaskExemption = {
  types: {
    nhs: 'nhs',
    hiddenDisabilities: 'hiddenDisabilities',
    unknown: 'unknown'
  },
  names: {
    nhs: 'NHS',
    hiddenDisabilities: 'Hidden Disabilities',
    unknown: 'Unknown'
  }
};

const test = {
  types: {
    pcr: 'RT-PCR',
    latflow: 'Lateral Flow Test (LFT)',
    lamp: 'LAMP'
  },
  names: {
    pcr: 'Covid19 Swab Test',
    latflow: 'Covid19 Swab Test',
    lamp: 'Covid19 Saliva Test'
  },
  results: {
    negative: 'negative',
    invalid: 'invalid',
    positive: 'positive',
  },
  indicators: {
    positive: 'danger',
    negative: 'success',
    invalid: 'warning',
    none: 'none'
  },
  details: {
    positive: [
      'Your Coronavirus test result was positive. It is likely you had Coronavirus when the test was done.',
      'You and anyone you live with must self-isolate for 14 days immediately.',
      'You could be fined if you do not self-isolate after getting a positive test result.'
    ],
    negative: [
      'Your Coronavirus test result was negative.'
    ],
    invalid: [
      'Your sample could not be read and returned an invalid (void) result.',
      'Please arrange to have a repeat test as soon as possible.'
    ]
  },
  banners: {
    positive: 'positive',
    negative: 'negative',
    invalid: 'invalid result'
  }
};

const theme = {
  keys: {
    theme: 'theme'
  },
  types: {
    light: 'light',
    dark: 'dark',
    default: 'light'
  }
};

const VACCINE_FIRST_DOSE = 'Dose 1 of 2';
const VACCINE_SECOND_DOSE = 'Dose 2 of 2';

const vaccine = {
  names: [
    'AstraZeneca',
    'Convidecia',
    'Janssen',
    'Moderna',
    'Pfizer',
    'Sputnik Light',
    'Sputnik V',
    'ZyCoV-D',
  ],
  doses: [
    VACCINE_FIRST_DOSE,
    VACCINE_SECOND_DOSE,
    'Booster',
    'Clinical trial',
    'Other',
  ],
  firstDose: VACCINE_FIRST_DOSE,
  secondDose: VACCINE_SECOND_DOSE,
};

const notification = {
  actions: {
    accept: 'accept',
    reject: 'reject'
  },
  types: {
    monitoringRequest: 'monitoringRequest',
    monitoringAccepted: 'monitoringAccepted',
    monitoringRejected: 'monitoringRejected',
    overdueTestNotification: 'overdueTest',
    isolationRequest: 'isolationRequest',
    isolationEnded: 'isolationEnded',
    fitToFlyValid: 'fitToFlyValid',
    fitToFlyExpired: 'fitToFlyExpired',
    unknown: 'unknown',
  },
  titles: {
    monitoringRequest: 'Request to monitor Covid status',
    monitoringAccepted: 'Accepted Covid status monitoring',
    monitoringRejected: 'Rejected Covid status monitoring',
    overdueTest: 'You are overdue a test',
    isolationRequest: 'You have been asked to isolate',
    isolationEnded: 'Your isolation period has ended',
    fitToFlyValid: 'Fit to Fly pass',
    fitToFlyExpired: 'Your Fit to Fly pass has expired',
    unknown: 'Unknown',
  }
};

const genericErrorMessage = 'There was a problem. Please check that you are online.';

export {
  signInTimeout,
  notificationInterval,
  api,
  app,
  website,
  personal,
  timeline,
  onboarding,
  installation,
  facemaskExemption,
  test,
  theme,
  vaccine,
  notification,
  genericErrorMessage,
};
