import React from 'react';
import ReactDOM from 'react-dom';
import ReactModal from 'react-modal';

import './index.css';

import App from './App';

import * as serviceWorkerRegistration from './serviceWorkerRegistration';

// Set up React Modal
ReactModal.setAppElement('#root');

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// See: https://cra.link/PWA
serviceWorkerRegistration.register();
