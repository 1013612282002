import React from 'react';
import ReactSpinner from 'react16-spinjs';
import styled from 'styled-components';

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 998;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0,0,0,0.60);
`;

function Spinner({config, ...props}) {
  const opts = {
    lines: 8,
    color:'#FFD333',
    fadeColor: 'transparent',
    ...config
  };

  return (
    <Overlay>
      <ReactSpinner config={opts} {...props} />
    </Overlay>
  );
}

export default Spinner;
