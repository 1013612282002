import React from 'react';
import styled, { useTheme } from 'styled-components';
import { useHistory } from 'react-router-dom';
import { useLottie } from 'lottie-react';

import themes from '../../Common/theme';

import LightAnimationSrc from './json/light.json';
import DarkAnimationSrc from './json/dark.json';

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  border: none;

  display: flex;
  align-items: center;
  justify-content: center;

  background-color: ${props => props.theme.background};
`;

function SplashAnimation() {
  const history = useHistory();
  const theme = useTheme();

  const { View } = useLottie({
    autoPlay: true,
    loop: false,
    style: { width: '200px' },
    animationData: (theme === themes.light) ? LightAnimationSrc : DarkAnimationSrc,
    onComplete: () => setTimeout(() => history.replace('/'), 700)
  });

  return View;
}

function Splash({className, ...props}) {
  return (
    <Wrapper className={className}>
      <SplashAnimation />
    </Wrapper>
  );
}

export default Splash;
