import localforage from 'localforage';
import { extendPrototype } from 'localforage-getitems';
import moment from 'moment';
import jsonwebtoken from 'jsonwebtoken';

import { promisifyAPI, makeParameterURI } from './api';
import { restoreTimelineItems } from './timeline';
import { resetRoutes } from './routes';
import { emptyLocalStorage, addToLocalStorage } from './storage';
import {
  personal as PERSONAL,
  onboarding as ONBOARDING,
  timeline as TIMELINE
} from './constants';

// Needed to add getItems() to localforage
extendPrototype(localforage);

function makeAuthorizationHeader(jwt) {
  return {
    'Authorization': `Bearer ${jwt}`
  };
}

function makeUserCredentials() {
  return new Promise((resolve, reject) => {
    localforage.getItem(PERSONAL.keys.jwt)
    .then(jwt => {
      // Missing JWT?
      if (!jwt) {
        console.log('No API token found, requesting new JWT');
        return updateApiToken()
        .then(newJwt => {
          const header = makeAuthorizationHeader(newJwt);
          resolve(header);
        })
        .catch(reject);
      }

      // Valid JWT?
      const decodedJwt = jsonwebtoken.decode(jwt);
      if (!decodedJwt) {
        console.log('Invalid API token, requesting new JWT');
        return updateApiToken()
        .then(newJwt => {
          const header = makeAuthorizationHeader(newJwt);
          resolve(header);
        })
        .catch(reject);
      }

      // Expired JWT?
      const expires = moment.unix(decodedJwt.exp);
      if (moment().isAfter(expires)) {
        console.log('API token has expired, requesting new JWT');
        return updateApiToken()
        .then(newJwt => {
          const header = makeAuthorizationHeader(newJwt);
          resolve(header);
        })
        .catch(reject);
      }

      // All good, build the header and return as-is
      const header = makeAuthorizationHeader(jwt);
      resolve(header);
    })
    .catch(handleAPIError);
  });
}

// Ask the API to send a temporary PIN
function requestTemporaryPasscode(email) {
  return promisifyAPI('user/recover/send', { email }, 'POST');
}

// Recover an account using their email and account PIN
function recoverUser(email, passcode) {
  return new Promise((resolve, reject) => {
    promisifyAPI('user/recover/confirm', { email, passcode }, 'POST')
    .then(({ apiKey, id, passcodeChangeRequired }) => {
      // Update local storage with the user ID and the new API key
      // Then issue the recovery proper
      Promise.all([
        addToLocalStorage(PERSONAL.keys.id, id),
        addToLocalStorage(PERSONAL.keys.apiKey, apiKey),
        addToLocalStorage(PERSONAL.keys.passcodeChangeRequired, passcodeChangeRequired)
      ])
      .then(resolve)
      .catch(reject);
    })
    .catch(reject);
  });
}

function parseTimelineItem(item) {
  return {
    ...item,
    details: item.details ? item.details.split('|') : null
  };
}

// Retrieve user state from API
function restoreUser(email) {
  const uri = makeParameterURI('user', { email });

  return makeUserCredentials()
  .then(credentials => promisifyAPI(uri, null, 'GET', credentials))
  .then(data => {
    // Parse the results and copy it to local storage
    const validKeys = Object.keys(PERSONAL.keys).map(key => PERSONAL.keys[key]);
    Promise.all(
      Object.keys(data)
        .filter(key => validKeys.includes(key))
        .map(key => localforage.setItem(key, data[key]))
    )
    .catch(console.error);

    // Retrieve any organisations that the user belongs to
    getEmployers()
    .then(({ organisations }) => localforage.setItem(PERSONAL.keys.organisations, organisations))
    .catch(console.error);

    // Success! Grab the user's timeline items,
    // mark the user as onboarded and authenticated and then refresh.
    // This will take them to the timeline.
    restoreTimelineItems(email)
    .then(({ items }) => localforage.setItem(TIMELINE.keys.items, items.map(parseTimelineItem)))
    .catch(console.error)
    .finally(() => {
      Promise.all([
        localforage.setItem(ONBOARDING.keys.step, ONBOARDING.steps.complete),
        localforage.setItem(ONBOARDING.keys.complete, true),
        localforage.setItem(PERSONAL.keys.timestamp, new Date())
      ])
      .then(resetRoutes)
      .catch(console.error);
    });
  })
  .catch(handleAPIError);
}

function makeUserDetails() {
  const excluded = [
    PERSONAL.keys.timestamp,
    PERSONAL.keys.jwt,
    PERSONAL.keys.apiKey,
    PERSONAL.keys.notifications,
    PERSONAL.keys.organisations,
  ];

  const keys = Object.keys(PERSONAL.keys).filter(x => !excluded.includes(PERSONAL.keys[x])).map(x => PERSONAL.keys[x]);
  return localforage.getItems(keys);
}

function createUser(details) {
  return promisifyAPI('user/create', details, 'POST');
}

function checkUserEmail(email) {
  return promisifyAPI('user/check', { email }, 'POST');
}

function checkUserMobile(mobileNumber) {
  return promisifyAPI('user/check', { mobileNumber }, 'POST');
}

function updateUser() {
  return Promise.all([
    makeUserDetails(),
    makeUserCredentials()
  ])
  .then(([details, credentials]) => promisifyAPI('user', details, 'POST', credentials))
  .catch(handleAPIError);
}

function changePasscode(passcode) {
  return makeUserCredentials()
  .then(credentials => promisifyAPI('user/passcode', { passcode }, 'POST', credentials))
  .catch(handleAPIError);
}

function updateApiToken() {
  return new Promise((resolve, reject) => {
    Promise.all([
      localforage.getItem(PERSONAL.keys.id),
      localforage.getItem(PERSONAL.keys.apiKey)
    ])
    .then(([id, apiKey]) => {
      const uri = makeParameterURI('auth', { id, apiKey });
      return promisifyAPI(uri, null, 'GET')
      .catch(err => {
        console.error('Could not update API token');
        if (err.match(/Invalid API key/g)) {
          // Unable to update API key
          // Re-auth required
          return signOut();
        }
        reject(err);
      });
    })
    .then(({ jwt }) => {
      addToLocalStorage(PERSONAL.keys.jwt, jwt)
      .then(() => resolve(jwt))
      .catch(reject);
    })
  });
}

function addLateralFlowTest(serial, image) {
  return makeUserCredentials()
  .then(credentials => promisifyAPI('test/lateralflow', { serial, image }, 'POST', credentials))
  .catch(handleAPIError);
}

function getLateralFlowTest(serial) {
  const uri = makeParameterURI('test/lateralflow', { serial });

  return makeUserCredentials()
  .then(credentials => promisifyAPI(uri, null, 'GET', credentials))
  .catch(handleAPIError);
}

function getNotifications() {
  return new Promise((resolve, reject) => {
    return makeUserCredentials()
    .then(credentials => promisifyAPI('notifications', null, 'GET', credentials))
    .then(({ notifications }) => {
      addToLocalStorage(PERSONAL.keys.notifications, notifications)
      .then(() => resolve(notifications))
      .catch(reject);
    });
  });
}

function updateNotification(id, obj) {
  return localforage.getItem(PERSONAL.keys.notifications)
  .then(notifications => {
    const delta = notifications.map(x => x.id === id ? ({ ...x, ...obj }) : x);
    return addToLocalStorage(PERSONAL.keys.notifications, delta);
  })
  .then(makeUserCredentials)
  .then(credentials => promisifyAPI('notifications', { id, ...obj }, 'POST', credentials))
  .catch(handleAPIError);
}

function readNotification(id) {
  return updateNotification(id, { read: true });
}

function actionNotification(id, action) {
  return updateNotification(id, { action, read: true });
}

function deleteNotification(id) {
  return localforage.getItem(PERSONAL.keys.notifications)
  .then(notifications => {
    const delta = (notifications || []).filter(x => x.id !== id);
    return addToLocalStorage(PERSONAL.keys.notifications, delta);
  })
  .then(makeUserCredentials)
  .then(credentials => promisifyAPI('notifications', { id }, 'DELETE', credentials))
  .catch(handleAPIError);
}

function registerInterestInShop() {
  return makeUserCredentials()
  .then(credentials => promisifyAPI('shop/notify', {}, 'POST', credentials))
  .catch(() => {
    // Not critical, do nothing
  });
}

function getEmployers() {
  return makeUserCredentials()
  .then(credentials => promisifyAPI('user/organisations', null, 'GET', credentials))
  .catch(handleAPIError);
}

function updateEmployerMonitoring(id, monitoring) {
  return makeUserCredentials()
  .then(credentials => promisifyAPI('user/organisations', { id, monitoring }, 'PUT', credentials))
  .catch(handleAPIError);
}

function handleAPIError(err) {
  console.error(err);
}

function signOut() {
  return emptyLocalStorage().then(resetRoutes);
}

export {
  makeUserCredentials,
  requestTemporaryPasscode,
  recoverUser,
  restoreUser,
  createUser,
  updateUser,
  changePasscode,
  updateApiToken,
  addLateralFlowTest,
  getLateralFlowTest,
  getNotifications,
  updateNotification,
  readNotification,
  actionNotification,
  deleteNotification,
  checkUserEmail,
  checkUserMobile,
  registerInterestInShop,
  getEmployers,
  updateEmployerMonitoring,
  signOut
};
