import React from 'react';
import { Redirect } from 'react-router-dom';

import { app as APP } from './constants';
import { GenericRouter } from '../Components/Routes';

import Splash from '../Routes/Splash/Splash';

const Welcome = React.lazy(() => import('../Routes/Onboarding/Welcome'));
const Mobile = React.lazy(() => import('../Routes/Onboarding/Mobile'));
const Email = React.lazy(() => import('../Routes/Onboarding/Email'));
const Passcode = React.lazy(() => import('../Routes/Onboarding/Passcode'));
const Terms = React.lazy(() => import('../Routes/Onboarding/Terms'));
const Privacy = React.lazy(() => import('../Routes/Onboarding/Privacy'));

const Recover = React.lazy(() => import('../Routes/Recover/Welcome'));
const RecoverPasscode = React.lazy(() => import('../Routes/Recover/Passcode'));
const RecoverFinish = React.lazy(() => import('../Routes/Recover/Finish'));

const Home = React.lazy(() => import('../Routes/Home/Home'));
const Pass = React.lazy(() => import('../Routes/Home/Pass'));
const Test = React.lazy(() => import('../Routes/Home/Test'));
const Timeline = React.lazy(() => import('../Routes/Home/Timeline'));

const Profile = React.lazy(() => import('../Routes/Profile/Profile'));
const Facemask = React.lazy(() => import('../Routes/Profile/Facemask'));
const Address = React.lazy(() => import('../Routes/Profile/Address'));
const Personal = React.lazy(() => import('../Routes/Profile/Personal'));
const ChangeEmail = React.lazy(() => import('../Routes/Profile/Email'));
const ChangeMobile = React.lazy(() => import('../Routes/Profile/Mobile'));
const VerifyMobile = React.lazy(() => import('../Routes/Profile/VerifyMobile'));

const Notifications = React.lazy(() => import('../Routes/Notifications/Notifications'));
const Details = React.lazy(() => import('../Routes/Notifications/Details'));
const Edit = React.lazy(() => import('../Routes/Notifications/Edit'));

const More = React.lazy(() => import('../Routes/More/More'));
const ChangePasscode = React.lazy(() => import('../Routes/More/ChangePasscode'));
const Shop = React.lazy(() => import('../Routes/More/Shop'));
const Monitoring = React.lazy(() => import('../Routes/More/Monitoring'));

const Install = React.lazy(() => import('../Routes/Installation/Install'));
const Installed = React.lazy(() => import('../Routes/Installation/Installed'));

const Donate = React.lazy(() => import('../Routes/Interstitials/Donate'));
const Advert = React.lazy(() => import('../Routes/Interstitials/Advert'));

const Error = React.lazy(() => import('../Routes/Error'));

const routes = [
  {
    path: '/index.html',
    exact: true,
    component: () => <Redirect to='/' />,
  },
  {
    path: '/home',
    component: GenericRouter,
    default: true,
    menu: true,
    routes: [
      {
        path: '/home/menu',
        component: Home,
        menu: true,
        default: true
      },
      {
        path: '/home/test',
        component: Test,
        menu: true
      },
      {
        path: '/home/timeline',
        component: Timeline,
        menu: true
      },
      {
        path: '/home/pass',
        component: Pass,
        menu: true
      }
    ]
  },
  {
    path: '/profile',
    component: GenericRouter,
    menu: true,
    routes: [
      {
        path: '/profile/view',
        component: Profile,
        menu: true,
        default: true
      },
      {
        path: '/profile/facemask',
        component: Facemask,
      },
      {
        path: '/profile/address',
        component: Address,
        menu: true
      },
      {
        path: '/profile/personal',
        component: Personal,
        menu: true
      },
      {
        path: '/profile/email',
        component: ChangeEmail,
        menu: true
      },
      {
        path: '/profile/mobile',
        component: ChangeMobile,
        menu: true
      },
      {
        path: '/profile/verifymobile',
        component: VerifyMobile,
        menu: true
      }
    ]
  },
  {
    path: '/notifications',
    component: GenericRouter,
    menu: true,
    routes: [
      {
        path: '/notifications/view',
        component: Notifications,
        menu: true,
        default: true
      },
      {
        path: '/notifications/edit',
        component: Edit,
        menu: true,
      },
      {
        path: '/notifications/details',
        component: Details,
        menu: true,
      }
    ]
  },
  {
    path: '/more',
    component: GenericRouter,
    menu: true,
    routes: [
      {
        path: '/more/view',
        component: More,
        menu: true,
        default: true
      },
      {
        path: '/more/shop',
        component: Shop,
        menu: true
      },
      {
        path: '/more/monitoring',
        component: Monitoring,
        menu: true
      },
      {
        path: '/more/passcode',
        component: ChangePasscode,
        menu: true
      }
    ]
  },
  {
    path: '/interstitials/donate',
    component: Donate
  },
  {
    path: '/interstitials/advert',
    component: Advert
  },
  {
    path: '/error',
    component: Error
  },
  {
    path: '/splash',
    component: Splash
  }
];

const onboarding = [
  {
    path: '/index.html',
    exact: true,
    component: () => <Redirect to='/' />,
  },
  {
    path: '/onboarding',
    component: GenericRouter,
    tinted: true,
    default: true,
    routes: [
      {
        path: '/onboarding/welcome',
        component: Welcome,
        tinted: true,
        default: true
      },
      {
        path: '/onboarding/mobile',
        component: Mobile,
        tinted: true
      },
      {
        path: '/onboarding/email',
        component: Email,
        tinted: true
      },
      {
        path: '/onboarding/passcode',
        component: Passcode,
        tinted: true
      },
      {
        path: '/onboarding/terms',
        component: Terms,
        tinted: true
      },
      {
        path: '/onboarding/privacy',
        component: Privacy,
        tinted: true
      }
    ]
  },
  {
    path: '/recover',
    component: GenericRouter,
    tinted: true,
    routes: [
      {
        path: '/recover/welcome',
        component: Recover,
        tinted: true,
        default: true,
      },
      {
        path: '/recover/passcode',
        component: RecoverPasscode,
        tinted: true
      },
      {
        path: '/recover/finish',
        component: RecoverFinish,
        tinted: true
      }
    ]
  },
  {
    path: '/error',
    component: Error
  },
  {
    path: '/splash',
    component: Splash
  }
]

const installation = [
  {
    path: '/index.html',
    exact: true,
    component: () => <Redirect to={'/'} />,
  },
  {
    path: '/install',
    component: Install,
    tinted: true,
    default: true
  },
  {
    path: '/installed',
    component: Installed,
    tinted: true,
  },
  {
    path: '/error',
    component: Error
  },
  {
    path: '/splash',
    component: Splash
  }
];

const resetRoutes = () => {
  const appBase = `${APP.path}/${APP.startPage}`;
  window.location.assign(window.location.origin + appBase);
}

export default routes;
export { routes, resetRoutes, onboarding, installation };
