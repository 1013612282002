const lightTheme = {
  name: 'light',
  background: '#FFFFFF',
  statusbar: '#306BAC',
  navbar: {
    color: {
      primary: '#000000',
      link: '#0076B6',
      badge: '#FF3730'
    },
    background: '#F9F9F9',
    statusbar: '#306BAC',
    border: '#F2F2F2'
  },
  menu: {
    background: '#0076B6',
    color: {
      primary: '#FFFFFF',
      faded: '#F5F5F5'
    }
  },
  text: {
    color: {
      primary: '#000000',
      secondary: '#545458',
      faded: '#707074',
      placeholder: '#545458',
      warning: '#ff9100',
      danger: '#FF3730',
      info: '#306BAC',
      link: '#5F48BB',
      success: '#34c756'
    }
  },
  timeline: {
    background: {
      primary: '#F5F5F7', // Message box header
      dark: '#EFEFF1', // Message box details
    },
    line: '#EFEFF1', // Main timeline line
    separator: 'rgba(60,60,67,0.36)', // Separates message box header and details
    indicator: {
      border: '#FFFFFF',
      success: '#34C756',
      warning: '#ff9100',
      danger: '#ff3730',
      info: '#0076B6',
      none: '#C4C4CA'
    },
    icon: '#0076B6',
    shadow: '0 4px 5px 0 rgba(128,128,128,0.14), 0 1px 10px 0 rgba(128,128,128,0.12), 0 2px 4px -1px rgba(128,128,128,0.20)'
  },
  onboarding: {
    background: '#F2F2F7',
  },
  pin: {
    border: {
      empty: '#545458',
      populated: '#306BAC'
    },
    color: '#000000',
    key: '#FFFFFF',
    selected: '#FFFFFF',
    outline: {
      gradientFrom: '#A4C4DA',
      gradientTo: '#306BAC'
    }
  },
  input: {
    separator: 'rgba(60,60,67,0.36)',
    background: '#FFFFFF',
    tinted: '#F5F5F7',
    color: '#000000',
  },
  toggle: {
    outer: 'rgba(120,120,128,0.16)',
    inner: '#FFFFFF',
    innerFalse: '#FFFFFF',
    success: '#34c756',
    shadow: '0 3px 8px 0 rgba(0,0,0,0.15), 0 3px 1px 0 rgba(0,0,0,0.06);'
  },
  button: {
    color: {
      primary: '#FFFFFF',
      secondary: '#F4F4FA',
      disabled: '#94949A'
    },
    background: {
      primary: '#0076B6',
      secondary: '#9120A5',
      disabled: '#DCDCE2',
    }
  },
  stars: {
    color: '#C4C4CA',
    selected: '#FFC800',
  },
  notifications: {
    unread: '#000000',
    read: '#7C7C82',
    badge: '#FF3730',
    separator: 'rgba(60,60,67,0.36)',
  },
  profile: {
    image: {
      background: '#E9E9EB',
      placeholder: '#999999',
      link: '#0076B6',
    }
  },
  modal: {
    background: '#E0E0E0'
  },
  passes: {
    close: '#DCDCE2'
  }
};

const darkTheme = {
  name: 'dark',
  background: 'black',
  statusbar: 'black',
  navbar: {
    color: {
      primary: '#FFFFFF',
      link: '#3DCCDC',
      badge: '#FF3730'
    },
    background: '#232323',
    statusbar: '#232323',
    border: '#232323'
  },
  menu: {
    background: '#3DCCDC',
    color: {
      primary: '#000000',
      faded: '#4B4B4B'
    }
  },
  text: {
    color: {
      primary: '#FFFFFF',
      secondary: '#CBCBCF',
      faded: '#A3A3A7',
      placeholder: '#CBCBCF',
      warning: '#EC9B2F',
      danger: '#FF423B',
      info: '#3DCCDC',
      link: '#5F48BB',
      success: '#5CD277'
    }
  },
  timeline: {
    background: {
      primary: '#2C2C2E',
      dark: '#1C1C1E',
    },
    line: '#FFFFFF',
    separator: 'rgba(60,60,67,0.36)',
    indicator: {
      border: '#000000',
      success: '#49CD67',
      warning: '#FF9C19',
      danger: '#FF423B',
      info: '#3DCCDC',
      none: '#64646C'
    },
    icon: '#3DCCDC',
    shadow: '0 0 0 0 rgba(255,255,255,0.15)'
  },
  onboarding: {
    background: '#000000',
  },
  pin: {
    border: {
      empty: '#CBCBCF',
      populated: '#3DCCDC'
    },
    color: '#F0F0F0',
    key: '#000000',
    selected: '#F0F0F0',
    outline: {
      gradientFrom: '#87ADC9',
      gradientTo: '#3F75B2'
    }
  },
  input: {
    separator: 'rgba(84,84,88,0.65)',
    background: '#1C1C1E',
    tinted: '#1C1C1E',
    color: '#F0F0F0',
  },
  toggle: {
    outer: '#94949A',
    inner: '#F0F0F0',
    innerFalse: '#64646c',
    success: '#5BD076',
    shadow: '0 3px 8px 0 rgba(0,0,0,0.15), 0 3px 1px 0 rgba(0,0,0,0.06)'
  },
  button: {
    color: {
      primary: '#000000',
      secondary: '#000000',
      disabled: '#94949A'
    },
    background: {
      primary: '#3DCCDC',
      secondary: '#B269E4',
      disabled: '#4C4C52',
    }
  },
  stars: {
    color: '#C4C4CA',
    selected: '#FFC800',
  },
  notifications: {
    unread: '#FF9C19',
    read: '#49CD67',
    badge: '#FF423B',
    separator: 'rgba(60,60,67,0.36)',
  },
  profile: {
    image: {
      background: '#3A3A3C',
      placeholder: '#999999',
      link: '#3DCCDC',
    }
  },
  modal: {
    background: '#181818'
  },
  passes: {
    close: '#DCDCE2'
  }
};

const themes = {
  light: lightTheme,
  dark: darkTheme,
};

function userPrefersDarkMode() {
  if (window.matchMedia) {
    return window.matchMedia('(prefers-color-scheme: dark)').matches;
  }
  return false;
}

export { lightTheme, darkTheme, themes, userPrefersDarkMode };
export default themes;
