function getPWADisplayMode() {
  const isStandalone = window.matchMedia('(display-mode: standalone)').matches;
  if (document.referrer.startsWith('android-app://')) {
    // "Trusted Web Activity", a PWA that has been installed via the Play Store
    return 'twa';
  } else if (navigator.standalone || isStandalone) {
    return 'standalone';
  }
  return 'browser';
}

function isStandaloneMode(){
  const mode = getPWADisplayMode();
  return mode === 'twa' || mode === 'standalone';
}

// Browser detection
const platform = (()=> {
  var versionSearchString = '';

	const dataBrowser = [{
		prop: window.opera,
		identity: 'Opera',
		versionSearch: 'Version'
	}, {
		string: navigator.userAgent,
		subString: 'CriOS',
		identity: 'Chrome for iOS',
		versionSearch: 'CriOS',
		osVersionSearch: 'Version'
	},{
		string: navigator.userAgent,
		subString: 'EdgiOS',
		identity: 'Edge for iOS',
		versionSearch: 'EdgiOS',
		osVersionSearch: 'Version'
	},{
		string: navigator.userAgent,
		subString: 'Chrome',
		identity: 'Chrome'
	}, {
		string: navigator.userAgent,
		subString: 'OmniWeb',
		versionSearch: 'OmniWeb/',
		identity: 'OmniWeb'
	}, {
		string: navigator.vendor,
		subString: 'Apple',
		identity: 'Safari',
		versionSearch: 'Version|OS'
	}, {
		string: navigator.vendor,
		subString: 'iCab',
		identity: 'iCab'
	}, {
		string: navigator.vendor,
		subString: 'KDE',
		identity: 'Konqueror'
	}, {
		string: navigator.userAgent,
		subString: 'Firefox',
		identity: 'Firefox'
	}, {
		string: navigator.vendor,
		subString: 'Camino',
		identity: 'Camino'
	}, { // for newer Netscapes (6+)
		string: navigator.userAgent,
		subString: 'Netscape',
		identity: 'Netscape'
	}, {
		string: navigator.userAgent,
		subString: 'MSIE',
		identity: 'Explorer',
		versionSearch: 'MSIE'
	}, {
    string: navigator.userAgent,
    subString: 'Trident',
		identity: 'Explorer',
		versionSearch: 'rv'
  }, {
    string: navigator.userAgent,
		subString: 'Edge',
		identity: 'Edge'
	}, {
		string: navigator.userAgent,
		subString: 'Gecko',
		identity: 'Mozilla',
		versionSearch: 'rv'
	}, { // for older Netscapes (4-)
		string: navigator.userAgent,
		subString: 'Mozilla',
		identity: 'Netscape',
		versionSearch: 'Mozilla'
	}];

	const dataOS = [{
		string: navigator.platform,
		subString: 'Win',
		identity: 'Windows',
    versionSearch: 'Windows NT'
	}, {
		string: navigator.userAgent,
		subString: 'iPhone',
		identity: 'iOS',
    versionSearch: 'Version'
	}, {
		string: navigator.userAgent,
		subString: 'iPad',
		identity: 'iPadOS',
    versionSearch: 'Version|OS'
	}, {
		string: navigator.platform,
		subString: 'Mac',
		identity: 'OS X'
	}, {
		string: navigator.userAgent,
		subString: 'Android',
		identity: 'Android'
	}, {
		string: navigator.platform,
		subString: 'Linux',
		identity: 'Linux'
	}];

  return init(navigator.userAgent, navigator.appVersion);

  function init(userAgent, appVersion) {
		return {
      browser: searchString(dataBrowser) || 'Unknown browser',
      version: searchVersion(userAgent) || searchVersion(appVersion) || 'Unknown version',
      os: searchString(dataOS) || 'Unknown OS',
      osVersion: searchVersion(userAgent) || 'Unknown version'
    }
	}

	function searchString(data) {
		for (var i = 0; i < data.length; i++) {
			var dataString = data[i].string;
			var dataProp = data[i].prop;
			versionSearchString = data[i].versionSearch || data[i].identity;
			if (dataString) {
        if (dataString.indexOf(data[i].subString) !== -1) {
          return data[i].identity;
        }
			} else if (dataProp) {
        return data[i].identity;
      }
		}
	}

	function searchVersion(dataString) {
    var a = versionSearchString.split('|');
    for (var searchString of a) {
      var regex = new RegExp('(' + searchString + ').(\\S+)');
      var match = regex.exec(dataString);
  		if (match == null) continue;
  		return parseFloat(match[2]);
    }
	}
})();

export {
  isStandaloneMode,
  platform
}
