import { api as API } from './constants';

// Standard error responses
const errors = {
  offline: 'Application offline',
  apiUnreachable: 'Error contacting API',
  apiRejected: 'API rejected the request',
  badRequest: 'Bad request',
  notFound: 'Not found',
  notAuthorized: 'Not authorised',
};

// Helper method to build the error message
// Format is errorType: errorMessage
function makeErrorMessage(error, message) {
  if (message) {
    return `${error}: ${message}`;
  }

  return error;
}

// Helper method for parsing a response body (in JSON format)
// Throws appropriate errors for non-200 HTTP codes
function parseJsonBody(res) {
  return new Promise((resolve, reject) => {
    res.json()
    .then(body => {
      if (!res.ok) {
        switch(res.status) {
          case 200:
            break;

          case 400:
            return reject(makeErrorMessage(errors.badRequest, body.message));

          case 401:
            return reject(makeErrorMessage(errors.notAuthorized, body.message));

          case 404:
            return reject(makeErrorMessage(errors.notFound, body.message));

          default:
            return reject(makeErrorMessage(errors.apiUnreachable, body.message));
        }
      }

      resolve(body);
    })
    .catch(reject);
  });
}

// Calls a specified API endpoint
function promisifyAPI(endpoint, body, method = 'POST', headers) {
  return new Promise((resolve, reject) => {
    if (!navigator.onLine) {
      reject(errors.offline);
    }

    let opts = { method };

    if (body) {
      opts.body = JSON.stringify(body);
    }

    if (headers) {
      opts.headers = headers;
    }

    fetch(`${API.uri}/${endpoint}`, opts)
    .then(parseJsonBody)
    .then(body => {
      if (!body.success) {
        reject(errors.apiRejected);
      }

      resolve(body);
    })
    .catch(err => {
      console.error(`Error during ${method} to '${API.uri}/${endpoint}':`, err);
      reject(err);
    });
  });
}

function makeParameterURI(endpoint, params) {
  return endpoint + Object.keys(params).reduce((str, x) => {
    const key = encodeURIComponent(x);
    const val = encodeURIComponent(params[x]);
    return `${str}${str ? '&' : '?'}${key}=${val}`;
  }, '');
}

export { errors, parseJsonBody, promisifyAPI, makeParameterURI };
